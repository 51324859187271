import React from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { createAddShippingQuote } from '../actions/shipping';
import { closePopup } from '../actions/popup';

import Form from './Form';

class AddShippingQuotePopup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      account_number: ''
    };

    ['onClose', 'onCreateAccount', 'onFieldChange'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  onClose() {
    this.props.dispatch(closePopup());
  }

  onCreateAccount() {
    const {
      account_number
    } = this.state;

    const {
      courier_id,
      dispatch,
      job_id
    } = this.props;

    if (!account_number) {
      return;
    }
    dispatch(createAddShippingQuote(job_id, courier_id, account_number));
  }

  onFieldChange(value, field) {
    this.setState({ [field]: value });
  }

  render() {
    const { courier, index } = this.props;
    return (
      <div className="reveal small" style={{ display: 'block', height: 'initial', overflow: 'visible', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <div className="small-12 columns">
            <h3>Add Shipping Quote</h3>
          </div>
        </div>
        <Form className="row popup-content form"
          object={this.state}
          onFieldChange={this.onFieldChange}
        >
          <div className="small-12 columns">
            <div className="field row">
            </div>
            <Form.TextInput label="Shipping Quote #" field="account_number" required={true} />
          </div>
        </Form>
        <div className="row">
          <div className="small-12 columns">
            <a className="button" onClick={this.onCreateAccount} style={{ float: 'right', marginLeft: '1rem' }}>Create</a>
            <a className="button" onClick={this.onClose} style={{ float: 'right' }}>Cancel</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const courier_id = state.entities.ship_methods[ownProps.ship_method_id].courier_id;
  const courier = state.entities.couriers[courier_id];
  return {
    courier_id,
    courier
  };
};

const ConnectedAddShippingQuotePopup = connect(mapStateToProps)(AddShippingQuotePopup);

export default ConnectedAddShippingQuotePopup;
